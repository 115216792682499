.registerContainer {
    width: 40%;
    margin: auto;
    text-align: center;
    /* background: rgb(239, 151, 92);
    background: linear-gradient(0deg, rgb(236, 167, 111) 60%, rgba(251,247,247,1) 100%); */
    display: block;
  }
  
  .registerHeader {
    display: flex;
    /* padding: 20px 10px; */
    align-items: center;
    /* background: rgb(244, 168, 82);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  }
  
  .registerHeader > h1 {
    margin-left: 34%;
    font-size: 25px;
    color: white;
  }
  
.register-form-outer{
  /* background-image: url('./p4.jpg'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

  .registerContainer > a > img {
    width: 180px;
    height: 50px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 50px;
    /* box-shadow:0px 3px 4px; */
    /* border-radius: 50%; */
  }
  
  .inputWrapper {
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    height: 65px;
    margin-bottom: 30px;
    border-radius: 50px;
    padding: 0 10px;
    background-color:white;
    border: 1px solid #ccc;
  }
  
  .phoneIcon {
    width: 24px; /* Adjust size as needed */
    height: 24px; /* Adjust size as needed */
    margin-right: 10px;
  }
  
  .countryCode {
    font-size: 18px;
    margin-right: 10px;
  }
  
  .inputWrapper > input {
    width: calc(100% - 70px); /* Adjust width to accommodate the button */
    border: none;
    outline: none;
    height: 60px;
    border-radius: 30px;
    padding-left: 10px;
    /* background-color: #e7e5e5;  */
  }
  
  .inputWrapper > input::placeholder {
    color: #ccc;
  }
  
  .sendButton {
    background-color: #21110b !important;
    color: white;
    width: 90px;
    border: none;
    font-size: 10px;
    border-radius: 10px;
    padding: 10px 5px;
    cursor: pointer;
  }
  
  /* .sendButton:hover {
    background-color: #4a3025;
  } */

  .signUp{
    width: 90%;
    background: rgb(40, 53, 192);
    background: radial-gradient(circle, rgb(40, 53, 192) 50%, rgba(251,247,247,1) 120%);
    padding: 10px 20px;
    border-radius: 20px;
    margin-top: 30px;
    font-size: 25px;
    color: white;
    margin-bottom: 40px;
  }

  .exist{
    display: flex;
    width: 90%;
    margin: auto;
    /* border: 1px solid red; */
    justify-content: right;
    gap: 10px;
    font-weight: 600;
    padding: 0px 10px;
  }

  .exist > a{
    color: red;
  }
  
  @media screen and (max-width: 1000px) {
    .registerContainer {
      width: 100%;
    }
    .registerHeader > h1 {
        margin-left: 20%;
      }

      .inputWrapper > input {
        width: calc(100% - 70px); /* Adjust width to accommodate the button */
        border: none;
        outline: none;
        height: 60px;
        border-radius: 30px;
        padding-left: 10px;
        /* background-color: white; */
      }
  }
  







  /* Register.css */
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blurry effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
