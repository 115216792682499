@import url('https://fonts.googleapis.com/css2?family=Futura:wght@700&display=swap');

.footer{
    width: 40%;
    margin: auto;
    background-color: black;
    color: white;
    text-align: center;
    height: 510px;
}

.footer-heading > h1{
    font-size: 100px;
    font-weight: 1000;
    font-family: 'Futura', sans-serif;
}

.footer-heading > div{
    display: flex;
    width: 80%;
    margin: auto;
    gap: 10px;
    row-gap: 1;
    font-size: 20px;
    color: grey;
    /* text-align: center; */
    justify-content: center;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}

.footer-heading > div > p:hover{
    cursor: pointer;
    color: rgb(57, 214, 57);
    text-decoration: underline;
}

.footer-body{
    color: grey;
    width: 80%;
    margin: auto;
    margin-top: 40px;   
}

.footer-body > h3{
    font-size: 20px;
    font-weight: 600;
}

.body-dis{
    margin-top: 20px;
}



.social-icons{
    width: 40%;
    display: flex;
    gap: 20px;
    color: rgb(193, 188, 188);
    margin: auto;
    margin-top: 10px;
    justify-content: center;
    font-size: 30px;
    /* border: 1px solid red; */
}

.social-icons > i:hover{
    cursor: pointer;
    color: rgb(57, 214, 57);
}

.follow{
    font-size: 25px;
    color: rgb(202, 199, 199);
    margin-top: 30px;
}


@media only screen and (max-width: 620px) {
    .footer{
        /* height: 400px; */
        /* border: 1px solid red; */
        width: 100%
    }
}