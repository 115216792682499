.swiper {
  
    width: 68%;
    height: 200px;
    margin-top: 60px;
    z-index: 0;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    background: linear-gradient(to bottom,#67b5f4, #8dea64 );
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 0; */
  }
  
  .swiper-slide img {
    display: block;
    width: 30%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  


  @media only screen and (max-width: 600px) {
    .swiper {
        width: 76%;
        height: 300px;
        margin-top: 60px;
      }
      
      .swiper-slide {
        background-position: center;
        background-size: cover;
        border-radius: 1%;
        background: linear-gradient(to bottom,#67b5f4, #8dea64 );
      }
      
      .swiper-slide img {
        display: block;
        width: 40%;
        height: auto;
      }
      
}