.total-earning {
    width: 40%;
    margin: auto;
    background: linear-gradient(to bottom, #68D391, #4299E1);
    padding-bottom: 50px;
    /* padding-top: 50px; */
    /* border-radius: 20px; */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    /* transform: perspective(1000px) rotateX(10deg); */
  }
  
  .header-3d {
    border-radius: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .income-image {
    display: block;
    margin: 50px auto 20px auto;
    width: 150px;
    /* transform: perspective(1000px) rotateX(15deg); */
    /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); */
    border-radius: 50%;
  }
  
  .earnings-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    gap: 20px;
    padding: 0 10%;
    margin-top: 50px;
  }
  
  .earning-item {
    padding: 20px 15px;
    background: linear-gradient(to right, #F6E05E, #F97316);
    height: 80px;
    border: 2px solid white;
    display: grid;
    color: rgb(248, 243, 243);
    align-items: center;
    text-align: center;
    border-radius: 10px;
    transition: all 0.3s ease;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    /* transform: perspective(1000px) rotateX(10deg); */
  }
  
  .earning-item:hover {
    cursor: pointer;
    background: linear-gradient(to left, #F6E05E, #F97316);
    color: black;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    transform: perspective(1000px) rotateX(0deg) translateY(-5px);
  }
  
  @media only screen and (max-width: 600px) {
    .total-earning {
      width: 100%;
      margin: auto;
    }
  
    .earnings-list {
      grid-template-columns: 1fr 1fr; /* One column on smaller screens */
    }
  
    .income-image {
      width: 120px;
    }
  
    .earning-item {
      padding: 15px 10px;
    }
  }
  